import "./sidebar.css";
import { RssFeed } from "@material-ui/icons";
import { Chat } from "@material-ui/icons";
import { PlayCircleFilledOutlined } from "@material-ui/icons";
import { Group } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import { HelpOutline } from "@material-ui/icons";
import { WorkOutline } from "@material-ui/icons";
import { Event } from "@material-ui/icons";
import { School } from "@material-ui/icons";
import User from "../user/User";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Sidebar({ user }) {
	const [users, setUsers] = useState(null);

	useEffect(() => {
		const fetchUsersData = async () => {
			const res = await axios.get(
				process.env.REACT_APP_API_URL + "/users/all"
			);
			setUsers(res.data);
		};
		fetchUsersData();
	}, []);

	return (
		<div className="sidebar">
			<div className="sidebarWrapper">
				<ul className="sidebarList">
					<li className="sidebarListItem">
						<RssFeed className="sidebarIcon" />
						<span className="sidebarListItemText">Feed</span>
					</li>
					<li className="sidebarListItem">
						<Chat className="sidebarIcon" />
						<span className="sidebarListItemText">Chats</span>
					</li>
					<li className="sidebarListItem">
						<PlayCircleFilledOutlined className="sidebarIcon" />
						<span className="sidebarListItemText">Videos</span>
					</li>
					<li className="sidebarListItem">
						<Group className="sidebarIcon" />
						<span className="sidebarListItemText">Groups</span>
					</li>
					<li className="sidebarListItem">
						<Bookmark className="sidebarIcon" />
						<span className="sidebarListItemText">Bookmarks</span>
					</li>
					<li className="sidebarListItem">
						<HelpOutline className="sidebarIcon" />
						<span className="sidebarListItemText">Questions</span>
					</li>
					<li className="sidebarListItem">
						<WorkOutline className="sidebarIcon" />
						<span className="sidebarListItemText">Jobs</span>
					</li>
					<li className="sidebarListItem">
						<Event className="sidebarIcon" />
						<span className="sidebarListItemText">Events</span>
					</li>
					<li className="sidebarListItem">
						<School className="sidebarIcon" />
						<span className="sidebarListItemText">Courses</span>
					</li>
				</ul>
				<button className="sidebarButton">Show More</button>
				<hr className="sidebarHr" />
				{users && (
					<ul className="sidebarFriendList">
						{users.map(
							(u) =>
								u._id !== user._id && (
									<Link
										key={u._id}
										to={
											"/profile/" +
											u.firstname +
											"." +
											u.lastname
										}
										style={{ textDecoration: "none" }}
									>
										<User key={u._id} user={u} />
									</Link>
								)
						)}
					</ul>
				)}
			</div>
		</div>
	);
}
